import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CabinetsInterface } from '@features/cabinets/domain/entities/cabinets.interface';
import { CabinetsApiPorts } from '@features/cabinets/domain/ports/cabinets-api.ports';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CabinetsApiService implements CabinetsApiPorts {
  apiPath = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getCabinets(): Observable<CabinetsInterface[]> {
    return this.http.get<CabinetsInterface[]>(`${this.apiPath}/cabinets`);
  }
}
