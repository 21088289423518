import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CabinetsApiService } from '@features/cabinets/adapters/secondary/real/cabinets-api.service';
import { CabinetsRoutingModule } from '@features/cabinets/cabinets-routing.module';
import { CabinetsApiPorts } from '@features/cabinets/domain/ports/cabinets-api.ports';

@NgModule({
  providers: [{ provide: CabinetsApiPorts, useClass: CabinetsApiService }],
  imports: [CommonModule, CabinetsRoutingModule],
  declarations: [],
})
export class CabinetsModule {}
